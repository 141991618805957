import React, { useEffect } from "react"
import Header from "../components/Header"
import PreHero from "./PreHero"
import { Link } from "react-router-dom"
import Hero from "../components/home/Hero"

const Confirm: React.FC = () => {
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<>
			<div className="light bg-gradient-to-r from-[#F8DE4B]  to-[#EFBB27]">
				<Header />
				<Hero type="promoover" />
				<div className="bg-norepeat  relative w-full  bg-cover bg-center bg-repeat-y pt-[0px] lg:mt-[150px]  lg:pt-[75px]">
					<div className=" mx-auto max-w-[1400px] pt-12 text-center text-black lg:pt-6">
						<h3 className="mb-4 mt-24 px-4 font-FuturaBold text-[20px] uppercase lg:mt-12 lg:mb-12 lg:px-0 lg:text-[42px]">
							Unsere GRATIS-TESTEN-AKTION ist seit dem 15.10.2024 beendet.
						</h3>
						<p className="px-4font-FuturaBold mb-4 lg:mb-12 lg:text-[24px]">Vielen Dank an alle, die an unserer Aktion teilgenommen haben.</p>
						<p className="px-4font-FuturaBold mb-4 lg:mb-12 lg:text-[24px]">
							Du hast noch Fragen zur Aktion? Kein Problem! Kontaktiere uns einfach per E-Mail unter{" "}
							<a href="mailto:info@yopro-gratis-testen.de" className="underline" target="_blank" rel="norefferer">
								info@yopro-gratis-testen.de
							</a>
							.
						</p>
						<p className="mb-4 px-4 pb-12 font-FuturaBold lg:mb-12 lg:text-[24px]">
							Besuche uns gerne auf{" "}
							<a href="https://www.yopro.de" className="underline" target="_blank" rel="norefferer">
								www.yopro.de
							</a>
							, um nichts zu verpassen.
						</p>
					</div>
				</div>
			</div>
		</>
	)
}
export default Confirm
