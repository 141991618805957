import React from "react"
import { useContext } from "react"
import { Context } from "../../store/store"
import ElemInput from "../elements/Input"

interface BankAccountProps {
	formElementChanged: Function
	validation: Object
}
const BankAccount: React.FC<BankAccountProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<>
			<div className="container">
				<div className="mx-auto mb-6 grid max-w-[900px] grid-cols-1 items-start gap-1 md:grid-cols-2 lg:mt-0 lg:gap-5">
					<ElemInput
						label={"Kontoinhaber*"}
						props={{
							type: "text",
							name: "accountHolder",
							value: globalState.accountHolder,
							required: "required",
							placeholder: "Kontoinhaber*",
							minLength: 1,
							onChange: e => formElementChanged(e, "accountHolder"),
						}}
						hasError={validation["invalid_accountHolder"] ? true : false}
						className="col-span-2 md:col-span-1"
						errMsg={validation["invalid_accountHolder_errMsg"] || "Bitte Kontoinhaber angeben."}
					/>
					<ElemInput
						label={"IBAN*"}
						props={{
							type: "text",
							name: "iban",
							value: globalState.iban,
							required: "required",
							minLength: 16,
							placeholder: "IBAN*",
							maxLength: 31,
							onChange: e => formElementChanged({ target: { value: e.target.value.replace(" ", "").toUpperCase() } }, "iban"), // remove whitespaces
						}}
						hasError={validation["invalid_iban"] ? true : false}
						className="col-span-2 md:col-span-1"
						errMsg={validation["invalid_iban_errMsg"] || "Bitte IBAN angeben. "}
					/>
					{globalState.iban && globalState.iban.length > 2 && globalState.iban.substring(0, 2).toUpperCase() !== "DE" && (
						<ElemInput
							label={"BIC*"}
							props={{
								type: "text",
								name: "bic",
								value: globalState.bic,
								required: "required",
								placeholder: "BIC*",
								maxLength: 11,
								onChange: e => formElementChanged({ target: { value: e.target.value.replace(" ", "").toUpperCase() } }, "bic"),
							}}
							hasError={validation["invalid_bic"] ? true : false}
							className="col-span-2 md:col-span-1"
							errMsg={validation["invalid_bic_errMsg"] || "Bitte BIC angeben. "}
						/>
					)}
				</div>
			</div>
		</>
	)
}

export default BankAccount
