interface PreFillFormProps {
	formElementChanged: Function
}
const PreFillForm: React.FC<PreFillFormProps> = ({ formElementChanged }) => {
	return (
		<>
			{/* {process.env.NODE_ENV === "development" && ( */}
			<span
				className="btn btn-default cursor-pointer"
				onClick={() => {
					formElementChanged({ target: { value: "1" } }, "salutation")
					formElementChanged({ target: { value: "firstname" } }, "firstname")
					formElementChanged({ target: { value: "lastname" } }, "surname")
					formElementChanged({ target: { value: "ff@fake.de" } }, "email")
					formElementChanged({ target: { value: "1970-01-01" } }, "birthday")
					formElementChanged({ target: { value: "a" } }, "shop")
					formElementChanged({ target: { value: "2" } }, "purchaseAmount")
					formElementChanged({ target: { value: "asdf asd" } }, "accountHolder")
					formElementChanged({ target: { value: "DE75512108001245126199" } }, "iban")
					formElementChanged({ target: { value: "2024-06-17" } }, "purchaseDate")
				}}
			>
				+++ fill with testdata ++++
			</span>
			{/* )} */}
		</>
	)
}

export default PreFillForm
