import { Link } from "react-router-dom"
import React from "react"
import Slider from "react-slick"
import "../slickslider.css"

function Info() {
	var settings = {
		dots: false,
		infinite: true,
		speed: 100,
		slidesToShow: 2,
		slidesToScroll: 1,
		swipeToSlide: true,
		initialSlide: 0,
		arrows: false,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					dots: false,
				},
			},
			{
				breakpoint: 480,
				settings: {
					autoplay: true,
					autoplaySpeed: 3000,
					slidesToShow: 1.04,
					dots: false,
					speed: 300,
					infinite: true,
					slidesToScroll: 1,
				},
			},
		],
	}
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}

	return (
		<div>
			<div className="mx-auto mt-12 mb-12  bg-red p-6 pb-12 pt-12 lg:mt-24">
				<div className="mx-auto max-w-[1400px]">
					<h2 className="mx-auto mb-6 mt-0 max-w-[100%] pb-32 text-center font-FuturaBold text-[28px] uppercase text-[#F8DE4B] lg:mt-6 lg:mb-12 lg:max-w-[75%] lg:pb-24 lg:text-[58px] lg:text-white">
						Neu – high Protein mit Magnesium + Vitamin B9
					</h2>
				</div>
			</div>
			<div className="mx-auto -mt-48 hidden max-w-[1400px] lg:block">
				<Slider {...settings}>
					<div className="bg-gradient-to-l from-[#F8DE4B] to-[#EFBB27]  bg-cover bg-center p-6">
						<div className="grid grid-cols-1 justify-items-center lg:grid-cols-1">
							<a href="https://www.yopro.de/produkte.html" target="_blank" className="relative grid items-center self-center p-2 lg:p-6">
								<img src={process.env.PUBLIC_URL + "/images/danone/slider/drinks.png"} className="z-2 relative hidden h-[320px] w-auto lg:block" />
							</a>
						</div>
					</div>
					<div className="grid grid-cols-2 bg-gradient-to-l from-[#F8DE4B] to-[#EFBB27] p-6">
						<div className="grid grid-cols-1 justify-items-center lg:grid-cols-1">
							<a href="https://www.yopro.de/produkte.html" target="_blank" className="relative grid items-center self-center p-2 lg:p-6">
								<img src={process.env.PUBLIC_URL + "/images/danone/slider/skyr.png"} className="z-2 relative hidden h-[320px] w-auto lg:block" />
							</a>
						</div>
					</div>
				</Slider>
			</div>
			<div className="mx-auto -mt-48 block max-w-[1400px] lg:hidden">
				<Slider {...settings} className="block lg:hidden">
					<div className="bg-gradient-to-l from-[#F8DE4B] to-[#EFBB27]  bg-cover bg-center">
						<div className="grid grid-cols-1 justify-items-center lg:grid-cols-1">
							<div className="relative grid items-center self-center">
								<img src={process.env.PUBLIC_URL + "/images/danone/slider/drinks-mobile.jpg"} className="z-2 relative block w-auto lg:hidden" />
							</div>
						</div>
					</div>
					<div className="grid grid-cols-2 bg-gradient-to-l from-[#F8DE4B] to-[#EFBB27]">
						<div className="grid grid-cols-1 justify-items-center lg:grid-cols-1">
							<div className="relative grid items-center self-center">
								<img src={process.env.PUBLIC_URL + "/images/danone/slider/skyr-mobile.jpg"} className="z-2 relative block w-auto lg:hidden" />
							</div>
						</div>
					</div>
				</Slider>
			</div>
		</div>
	)
}

export default Info
