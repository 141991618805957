import { useContext } from "react"
import * as React from "react"
import { Context } from "../../../store/store"
import ElemCheckboxMafo from "../../elements/CheckboxMafo"

interface MafoQuestionProps {
	formElementChanged: Function
	validation: Object
}
const MafoQuestionOne: React.FC<MafoQuestionProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<>
			<h3 className="mb-6 text-left font-FuturaBold text-[17px] text-black lg:mb-12 lg:text-center lg:text-[24px]">
				Was schätzt du an YoPRO besonders?* (Mehrfachantwort möglich)
			</h3>
			<div className="mx-auto grid grid-cols-1 items-center gap-2 text-left lg:max-w-[75%] lg:grid-cols-2">
				<ElemCheckboxMafo
					label={<>Den Geschmack</>}
					props={{
						type: "checkbox",
						name: "question1",
						required: "required",
						value: "a",
						onClick: e => formElementChanged(e, "question1"),
					}}
					className=" question1 pb-2 text-black"
				/>
				<ElemCheckboxMafo
					label={<>Das Verpackungsdesign</>}
					props={{
						type: "checkbox",
						name: "question1",
						required: "required",
						value: "b",
						onClick: e => formElementChanged(e, "question1"),
					}}
					className=" question1 pb-2 text-black"
				/>
				<ElemCheckboxMafo
					label={<>Die Verpackungsgröße</>}
					props={{
						type: "checkbox",
						name: "question1",
						required: "required",
						value: "c",
						onClick: e => formElementChanged(e, "question1"),
					}}
					className=" question1 pb-2 text-black"
				/>
				<ElemCheckboxMafo
					label={<>Die Vorteile – Proteine, Magnesium, Vitamin B9</>}
					props={{
						type: "checkbox",
						name: "question1",
						required: "required",
						value: "d",
						onClick: e => formElementChanged(e, "question1"),
					}}
					className=" question1 pb-2 text-black"
				/>
				<ElemCheckboxMafo
					label={<>Die Sortenauswahl</>}
					props={{
						type: "checkbox",
						name: "question1",
						required: "required",
						value: "e",
						onClick: e => formElementChanged(e, "question1"),
					}}
					className=" question1 pb-2 text-black"
				/>
			</div>
		</>
	)
}

export default MafoQuestionOne
