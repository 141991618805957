import React, { useEffect } from "react"
import DocumentMeta from "react-document-meta"
import { useNavigate, Link } from "react-router-dom"
import RosaSection from "../components/elements/BeigeSection"
import Header from "../components/Header"

const Terms: React.FC = () => {
	const navigate = useNavigate()

	// SEO Meta Tags

	const meta = {
		// title: "Mit merci zum Muttertag filmreif Danke sagen!",
		// canonical: "https://arvato-hawaiian-tropic-2023.de/",
		// meta: {
		// name: {
		// robots: "noindex, nofollow",
		// },
		// },
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div>
			<div className="bg-gradient-to-r from-[#F8DE4B]  to-[#EFBB27]">
				<Header type="logo" />
				<div className="mx-auto py-8 px-4 text-black">
					<div className="mx-auto max-w-[800px]">
						<h2 className="h2 mb-8 mt-32 text-center font-FuturaBold text-2xl text-black lg:mt-24">Teilnahme&shy;bedingungen</h2>
						<h3 className="h3 py-4">1. Veranstalter</h3>
						<p className="mb-4">Veranstalter dieser Cashback-Aktion ist die Danone Deutschland GmbH, Am Hauptbahnhof 18, 60329 Frankfurt am Main, nachfolgend Danone genannt.</p>

						<h3 className="h3 py-4">2. Teilnahmeberechtigung</h3>
						<p className="mb-4">
							Teilnahmeberechtigt sind Personen mit Wohnsitz in Deutschland, die bei der Teilnahme mindestens 18 Jahre alt sind und ein Konto im SEPA Raum (ein Land innerhalb der
							Europäischen Union sowie Island, Lichtenstein, Norwegen, Schweiz, Andorra, Monaco, San Marino, Vatikanstadt, Vereinigtes Königreich, Gibraltar, Isle of Man, Guernsey
							und Jersey) besitzen. Mitarbeiter von Danone, deren Angehörige und alle an der Konzeption und Umsetzung Cashback Aktion beteiligten Personen sind von der Teilnahme
							ausgeschlossen. Teilnehmer der Cashback Aktion ist diejenige Person, der die E-Mail-Adresse gehört, die bei der Anmeldung zum Cashback Aktion angegeben worden ist.
						</p>
						<p className="mb-4">
							Pro Bankkonto ist eine Teilnahme an dieser Cashback Aktion möglich. Pro Teilnahme ist nur ein Kaufbeleg anwendbar und es wird nur eine gekaufte YoPRO Packung (exkl.
							Pfand) erstattet. Die Teilnahme mittels automatisierter Datenverarbeitungsprozesse und/oder über die Einschaltung Dritter (z.B. von Gewinnspielagenturen) ist
							ausgeschlossen. Eine regelwidrige Teilnahme eines Teilnehmers an dieser Cashback Aktion hat seinen Spielausschluss zur Folge.
						</p>
						<p className="mb-4">Mit der Teilnahme an der Cashback Aktion akzeptiert der Benutzer diese Teilnahmebedingungen.</p>
						<p className="mb-4">
							Um an der Cashback Aktion teilzunehmen, muss der Teilnehmer innerhalb des unten genannten Kaufzeitraumes ein an der Aktion teilnehmendes YoPRO Produkt im Handel oder
							online kaufen und den Kaufbeleg auf der Aktionswebsite{" "}
							<a href="www.yopro.de/gratis" className="underline" target="_blank">
								www.yopro.de/gratis
							</a>{" "}
							bis zum 14.10.2024, 23:59 Uhr hochladen und Pflichtfragen beantworten. Es nehmen alle YoPRO Produkte, die in Deutschland gekauft wurden, teil. Hierbei ist zu
							beachten, dass „YoPRO“, sowie das Kaufdatum schriftlich auf dem Kaufbeleg fixiert sein muss, um eine Nachvollziehbarkeit des Kaufs von YoPRO Produkten im
							Aktionszeitraum sicherzustellen. Das Bild des Kassenbons ist als JPG, PNG oder PDF hochzuladen und darf die maximale Dateigröße von 8 MB nicht überschreiten. Der
							Kaufzeitraum ist vom 01.07.2024 bis 30.09.2024. Der Teilnahmezeitraum ist online möglich bis zum 14.10.2024, 23.59 Uhr. Voraussetzung dafür ist ein gültiger
							Kassenbon, der den Kauf eines YoPRO Produkts im Kaufzeitraum nachweist.
						</p>
						<p className="mb-4">
							Danone behält sich vor, den Originalkassenbon bis zum Ablauf der Aktion für etwaige Nachprüfungen einzufordern. Zu diesem Zweck muss der Teilnehmer den Kassenbon an
							Danone Deutschland GmbH bzw. die durchführende Agentur, direct services Gütersloh GmbH senden.
						</p>
						<h3 className="h3 py-4">3. Cashback</h3>
						<p className="mb-4">
							Es wird nur der auf dem Kassenbon angegebene Kaufpreis für ein YoPRO Produkt (exkl. Pfand) einmalig erststattet. Im Falle mehrerer gekaufter YoPRO Produkte auf einem
							Kassenbon wird das teuerste YoPRO Produkt (exkl. Pfand) erstattet. Eine Kombination mit anderen Gutscheinen oder Aktionen ist nicht möglich. Auszahlungsansprüche sind
							nicht auf Dritte übertragbar.
						</p>
						<p className="mb-4">Danone erstattet einen Maximalbetrag von 5,00 Euro pro Aktionsprodukt.</p>
						<p className="mb-4">
							Dafür muss der Teilnehmer nach der Registrierung seinen Namen und seine IBAN im Teilnahmeformular angeben, sowie in einem Dropdown-Menü Pflichtfragen beantworten.
						</p>
						<p className="mb-4">
							Der Betrag wird innerhalb von 4 Wochen auf das von ihm angegebene Bankkonto erstattet. Eventuelle Gebühren, die die kontoführende Bank des Teilnehmers für den Empfang
							des Geldes oder die Umrechnung von Euro in andere Währungen berechnet, hat der Kontoinhaber selbst zu tragen.
						</p>
						<p className="mb-4">Die Aktion ist auf 50.000 Teilnahmen begrenzt.</p>

						<h3 className="h3 py-4">4. Rechte </h3>
						<p className="mb-4">
							Der Teilnehmer versichert, dass die eingereichten Daten von ihm selbst stammen, noch nicht anderweitig eingereicht worden sind und mit den eingereichten Daten nicht
							die guten Sitten, geltendes Recht oder Rechte Dritter verletzt werden. Die Entscheidungen der Danone sind endgültig. Diesbezügliche Anfragen können nicht beantwortet
							werden. Die Danone behält sich das Recht vor, die Aktion ganz oder zeitweise auszusetzen, wenn irgendwelche Umstände auftreten, die die Integrität der Aktion
							gefährden oder etwaigen rechtlichen Gründen entgegenstehen.
						</p>
						<p className="mb-4">
							Danone behält sich ferner das Recht vor, die Teilnahmebedingungen jederzeit zu ändern. Sollten einzelne Bestimmungen der Teilnahmebedingungen ungültig sein oder
							ungültig werden, bleibt die Gültigkeit der übrigen Teilnahmebedingungen unberührt. An ihre Stelle tritt die gültige Regelung, die dem Zweck der unwirksamen Bestimmung
							am ehesten entspricht.
						</p>
						<p className="mb-4">
							Jegliche Schadenersatzverpflichtung der Danone und ihrer Organe, Mitarbeiter und Erfüllungsgehilfen aus oder im Zusammenhang mit der Cashback Aktion , gleich aus
							welchem Rechtsgrund, ist – soweit gesetzlich zulässig – auf Fälle von Vorsatz oder grober Fahrlässigkeit beschränkt.
						</p>
						<p className="mb-4">Es gilt deutsches Recht. Der Rechtsweg ist ausgeschlossen.</p>

						<h3 className="h3 py-4">5. Haftung</h3>
						<p className="mb-4">
							Danone wird nach Erstattung des Kaufpreises von allen Verpflichtungen befreit, sofern sich nicht aus diesen Regelungen schon ein früherer Zeitpunkt ergibt. Eine
							Schadensersatzpflicht von Danone besteht nur, wenn und soweit ein Schaden auf Vorsatz oder grobe Fahrlässigkeit zurückzuführen ist. Für die schuldhafte Verletzung von
							Leib, Leben oder Gesundheit einer natürlichen Person haftet Danone auch bei nur einfacher Fahrlässigkeit. Gleiches gilt im Falle der Verletzung einer Kardinalpflicht.
							Das sind solche Pflichten, deren Erfüllung die ordnungsgemäße Durchführung der Aktion und die Erreichung des Vertragszwecks überhaupt erst ermöglichen und auf deren
							Einhaltung ein Aktionsteilnehmer regelmäßig vertrauen darf. Voranstehende Haftungsbeschränkung gilt insbesondere für Schäden, die durch Fehler, Verzögerungen oder
							Unterbrechungen in der Übermittlung von Daten, bei Störungen der technischen Anlagen oder des Service, durch unrichtige Inhalte, Verlust oder Löschung von Daten,
							Viren oder in sonstiger Weise bei der Teilnahme an der Cashback Aktion entstehen. Die Haftung nach dem Produkthaftungsgesetz bleibt unberührt.
						</p>
						<h3 className="h3 py-4">6. Rechtlicher Rahmen</h3>
						<p className="mb-4">
							Diese Teilnahmebedingungen sowie die Rechtsbeziehung zwischen dem Teilnehmer und Danone unterliegen ausschließlich deutschem Recht. Sollten einzelne Bestimmungen
							rechtlich ungültig sein oder werden, bleibt die Gültigkeit der übrigen Teilnahmebedingungen hiervon unberührt. Diese Teilnahmebedingungen können von Danone jederzeit
							ohne gesonderte Benachrichtigung geändert werden. Danone behält sich vor, die Aktion auch ohne Angabe von Gründen zu verlängern oder vorzeitig gegebenenfalls ohne
							Vorankündigung zu beenden bzw. die Zahl der Einlösungen im Verlauf der Aktion nachträglich zu limitieren. Dies kann insbesondere dann erfolgen, sollten
							Fehlfunktionen, Störungen, Fälschungen, Viren oder ähnliche Schäden auftreten oder ein Fall des Missbrauchs der Aktion vorliegen und die Administration, Sicherheit,
							Fairness, Integrität oder den Ablauf der Aktion beeinträchtigen. Aus vorzeitiger Beendigung können keine Ansprüche abgeleitet werden. Die Teilnahmebedingungen gelten
							für die Dauer der Aktion.
						</p>

						<h3 className="h3 py-4">Hinweise zum Datenschutz</h3>
						<p className="mb-4">
							Für die Verarbeitung der personenbezogenen Daten im Rahmen der Cashback-Aktion ist die Danone Deutschland GmbH, Am Hauptbahnhof 18, 60329 Frankfurt am Main,
							Deutschland (nachfolgend „Danone“ genannt) verantwortlich. Die uns mitgeteilten Daten verarbeiten wir ausschließlich zur Durchführung der Aktion und insbesondere um
							die Auszahlung der Cashback-Beträge vornehmen zu können. (Rechtsgrundlage Art. 6 Abs. 1 lit. b) und f) DSGVO). Die Kontaktdaten des Datenschutzbeauftragten von Danone
							lauten M. Lindner (ebDSB DANONE) c/o intersoft consulting services AG, Beim Strohhause 17, 20097 Hamburg,{" "}
							<a href="mailto:MLindner@intersoft-consulting.de" className="underline" target="_blank">
								MLindner@intersoft-consulting.de
							</a>
							. Alle personenbezogenen Daten der Teilnehmer werden ohne deren Einwilligung weder an Dritte weitergegeben noch diesen zur Nutzung zur Verfügung gestellt. Bei der
							Abwicklung unserer Aktion und bei der Verifizierung eingereichter Daten wird Danone von dessen Auftragsdatenverarbeiter, der Agentur direct services Gütersloh GmbH,
							Reinhard-Mohn-Str. 300, 33333 Gütersloh, unterstützt, der die Daten auf Grundlage vertraglicher Vereinbarungen nur entsprechend der Weisungen von Danone verarbeitet
							Die Daten der Teilnehmer werden – vorbehaltlich bestehender längerfristiger gesetzlicher Aufbewahrungsfrsiten – spätestens 3 Monate nach Auszahlung des
							Cashback-Betrags gelöscht. Selbstverständlich können Sie über die bei Danone gespeicherten Daten jederzeit Auskunft verlangen und / oder der Verwendung Ihrer Daten
							jederzeit ganz oder teilweise mit Wirkung für die Zukunft gegenüber Danone widersprechen, indem Sie einfach eine E-Mail an kontakt@danone.de schicken. Darüber hinaus
							hat jeder Teilnehmer die nach der DSGVO vorgesehenen Rechte auf Widerruf einer erteilten Einwilligung, Auskunft, Berichtigung, Löschung, Einschränkung der
							Verarbeitung, Datenübertragbarkeit sowie Beschwerde bei der zuständigen Datenschutzaufsichtsbehörde. Danone weist darauf hin, dass für die Durchführung der Aktion die
							Bereitstellung von personenbezogenen Daten des Teilnehmers erforderlich ist. Die Nichtbereitstellung der Daten und/oder die Geltendmachung von Löschungs-,
							Einschränkungs- und/oder Widerrufs- bzw. Widerspruchsrechten vor Abschluss der Aktion kann daher dazu führen, dass der jeweilige Teilnehmer von der weiteren
							Aktionsteilnahme ausgeschlossen wird und kein Cashback mehr ausgezahlt werden kann.
						</p>
						<p className="mb-4">
							Des Weiteren kann Danone Ihre personenbezogenen Daten auf der Grundlage Ihrer im Teilnahmeformular erteilten Einwilligungen verarbeiten. Diese Einwilligungen sind
							freiwillig und müssen nicht zwingend für die Teilnahme an der Aktion erteilt werden. Sie können diese Einwilligungen jederzeit mit Wirkung für die Zukunft widerrufen.
							Bei Rückfragen wenden Sie sich gerne jederzeit an{" "}
							<a className="underline" target="_blank" href="mailto:kontakt@danone.de">
								kontakt@danone.de
							</a>
							. Informationen zum Datenschutz im Zusammenhang mit dem Gewinnspiel sind auf folgender Website verfügbar:{" "}
							<Link to={"/datenschutz"} className="underline" target="_blank">
								Datenschutz
							</Link>
						</p>
						<p className="mb-4">
							Allgemeine Informationen zum Datenschutz bei YoPRO und Danone sind auf folgender Webseite verfügbar:{" "}
							<a href="https://www.yopro.de/datenschutzklaerung.html" className="underline" target="_blank">
								https://www.yopro.de/datenschutzklaerung.html
							</a>
						</p>
						<p className="mb-4">
							Wenn Sie weitere Informationen zum Datenschutz haben wollen, dann klicken Sie bitte hier:{" "}
							<a className="underline" target="_blank" href="mailto:kontakt@danone.de">
								kontakt@danone.de
							</a>
							.{" "}
						</p>
					</div>
					<div className=" py-10 pt-5 text-center text-xl text-black">
						<Link to="/" className="btn btn-hero  mt-2 bg-white px-12 py-4 font-FuturaBold text-red">
							Zurück
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}
export default Terms
