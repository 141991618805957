import React from "react"

interface AccordionItemProps {
	children?: any
	headline?: string
	num?: string
}

const AccordionItem: React.FC<AccordionItemProps> = ({ children, headline, num }) => {
	return (
		<label className="radius-[10px] relative mb-6 block rounded-[10px] bg-white pt-4 pb-4 pl-[40px] pr-14 text-black lg:pl-[50px]">
			<input type={"checkbox"} className="peer absolute h-0 opacity-0" />
			<h3 className="h3 absolute left-2 top-[8px]">
				<span className="text-bold mr-6 font-FuturaBook text-[18px] leading-[2.5rem] lg:text-[24px]">{num}</span>
			</h3>
			<h5 className="h5 -mt-2 font-FuturaBook text-[18px] font-bold leading-[2.5rem] lg:text-[22px]">{headline}</h5>
			<svg className="absolute right-8 top-4 w-6 peer-checked:-rotate-180" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ExpandMoreIcon">
				<path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z" className="fill-black" />
			</svg>
			<div className=" -ml-8 max-h-0 overflow-x-hidden pl-[33px] text-white transition-all duration-500 ease-in-out peer-checked:max-h-[2700px] peer-checked:pt-6 peer-checked:pb-5 sm:ml-0 lg:pl-[0px]  peer-checked:lg:max-h-[1700px]">
				{children}
			</div>
		</label>
	)
}
export default AccordionItem
