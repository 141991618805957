import * as React from "react"
import ElemCheckboxMafo from "../../elements/CheckboxMafo"

interface MafoQuestionProps {
	formElementChanged: Function
	validation: Object
}
const MAfoQuestionSeven: React.FC<MafoQuestionProps> = ({ formElementChanged, validation }) => {
	return (
		<>
			<h3 className="mb-6 text-left font-FuturaBold text-[17px] text-black lg:mb-12 lg:text-center lg:text-[24px]">Würdest du das YoPRO Produkt wieder kaufen?</h3>
			<div className="mx-auto grid grid-cols-1 gap-2 text-left lg:max-w-[75%] lg:grid-cols-2">
				<ElemCheckboxMafo
					label={<>Sehr wahrscheinlich</>}
					props={{
						type: "radio",
						name: "question7",
						value: "a",
						onClick: e => formElementChanged(e, "question7"),
					}}
					hasError={validation["invalid_question7"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question7_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Wahrscheinlich</>}
					props={{
						type: "radio",
						name: "question7",
						value: "b",
						onClick: e => formElementChanged(e, "question7"),
					}}
					hasError={validation["invalid_question7"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question7_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Eher unwahrscheinlich</>}
					props={{
						type: "radio",
						name: "question7",
						value: "c",
						onClick: e => formElementChanged(e, "question7"),
					}}
					hasError={validation["invalid_question7"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question7_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Unwahrscheinlich</>}
					props={{
						type: "radio",
						name: "question7",
						value: "d",
						onClick: e => formElementChanged(e, "question7"),
					}}
					hasError={validation["invalid_question7"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question7_errMsg"] || ""}
				/>
			</div>
		</>
	)
}

export default MAfoQuestionSeven
