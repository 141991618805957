import * as React from "react"
import { motion, useInView, useMotionValueEvent, useScroll, useTransform } from "framer-motion"

interface HeroProps {
	type?: any
}

const Hero: React.FC<HeroProps> = ({ type = "" }) => {
	return (
		<section className="lg:bg-center-bottom arrow-after relative top-0 z-10 mx-auto  h-[460px] w-full md:mt-12 md:h-[350px] lg:mt-0   lg:bg-red xl:h-[610px]">
			<div className="mx-auto grid h-[250px] max-w-[1400px] bg-red py-8 lg:grid-cols-[40%_60%] lg:px-12 lg:py-24 xl:grid-cols-2 ">
				<div className="relative justify-self-end px-6 py-6 lg:px-0 lg:py-0">
					<motion.img
						initial={{ opacity: 0, x: -100 }}
						animate={{ opacity: 1, x: 0 }}
						transition={{
							duration: 0.8,
							ease: "easeIn",
							delay: 1,
						}}
						src={process.env.PUBLIC_URL + "/images/danone/header-tag.png"}
						className="item-center relative z-[400] mb-5 mt-6 w-[200px] self-center lg:w-[75%] xl:mt-32"
					/>
				</div>
				<motion.section
					variants={{
						hidden: { opacity: 0 },
						show: {
							opacity: 1,
							transition: {
								staggerChildren: 0.25,
							},
						},
					}}
					className={
						" lg:bg-transparent md:bg-transparent relative -mt-[2px] grid  grid-cols-2 bg-gradient-to-r  from-[#F8DE4B] to-[#EFBB27] px-4 md:-mt-2  lg:mt-[12rem] lg:grid-cols-3 lg:from-[transparent]  xl:ml-24 xl:mt-0 " +
						(type === "promoover" || type === "prescreen" ? " mt-12" : "")
					}
				>
					<motion.img
						initial={{ rotate: 0, opacity: 0 }}
						animate={{ rotate: 0, opacity: 1 }}
						transition={{
							duration: 0.8,
							ease: "easeIn",
							delay: 0.5,
						}}
						src={process.env.PUBLIC_URL + "/images/danone/bottle-1.png"}
						className="item-center relative z-[10] -mt-32  ml-2 w-[130px] -rotate-6 self-center md:-mt-24 lg:-mt-[10rem] lg:ml-0 lg:w-[70%] xl:mt-24  xl:w-[100%]"
					/>

					<motion.img
						initial={{ rotate: 0, x: -200, opacity: 0 }}
						animate={{ rotate: 0, x: 125, opacity: 1 }}
						transition={{
							duration: 0.8,
							ease: "easeIn",
							delay: 0.75,
						}}
						src={process.env.PUBLIC_URL + "/images/danone/bottle-2.png"}
						className="item-center  absolute  top-2 bottom-0 z-[6] -ml-[3rem] w-[260px]  self-center lg:-mt-2 lg:-ml-4 lg:w-[220px] xl:top-[300px] xl:-ml-0 xl:w-[350px]"
					/>
				</motion.section>
				{type === "promoover" && (
					<div>
						<motion.img
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{
								duration: 0.3,
								ease: "easeIn",
								delay: 1.4,
							}}
							src={process.env.PUBLIC_URL + "/images/danone/promo-soon.png"}
							className=" absolute -bottom-20 z-50 lg:-bottom-[35%] lg:right-24"
						/>
					</div>
				)}
				{type === "promosoon" && (
					<div>
						<motion.img
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{
								duration: 0.3,
								ease: "easeIn",
								delay: 1.4,
							}}
							src={process.env.PUBLIC_URL + "/images/danone/promo-soon.png"}
							className=" absolute -bottom-20 z-50 lg:-bottom-[35%] lg:right-24"
						/>
					</div>
				)}
			</div>
		</section>
	)
}

export default Hero
