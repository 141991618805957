import { useContext, useState } from "react"
import { Link } from "react-router-dom"
import * as React from "react"
import { Context } from "../../store/store"
import Slider from "react-slick"
import "../../slickslider.css"
import MafoQuestionTwo from "./Mafos/MafoQuestionTwo"
import MafoQuestionThree from "./Mafos/MafoQuestionThree"
import MafoQuestionFour from "./Mafos/MafoQuestionFour"
import MafoQuestionFive from "./Mafos/MafoQuestionFive"
import MafoQuestionSix from "./Mafos/MAfoQuestionSix"
import MafoQuestionOne from "./Mafos/MafoQuestionOne"
import MAfoQuestionSeven from "./Mafos/MAfoQuestionSeven"
interface MafoProps {
	formElementChanged: Function
	validation: Object
}
const Mafo: React.FC<MafoProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	const [slider, setSlider] = useState<any>(null)
	const [isFirstSlide, setIsFirstSlide] = useState<Boolean>(false)
	const [isLastSlide, setIsLastSlide] = useState<Boolean>(true)

	const next = () => {
		slider?.slickNext()
	}
	const previous = () => {
		slider?.slickPrev()
	}
	var settings = {
		dots: true,
		infinite: false,
		speed: 100,
		slidesToShow: 1,
		arrows: false,
		slidesToScroll: 1,
		swipeToSlide: true,
		initialSlide: 0,
		// responsive: [
		// 	{
		// 		breakpoint: 1024,
		// 		settings: {
		// 			slidesToShow: 1,
		// 			arrows: false,
		// 			slidesToScroll: 3,
		// 			infinite: false,
		// 			dots: true,
		// 		},
		// 	},
		// 	{
		// 		breakpoint: 600,
		// 		settings: {
		// 			slidesToShow: 1,
		// 			slidesToScroll: 2,
		// 			dots: true,
		// 		},
		// 	},
		// 	{
		// 		breakpoint: 480,
		// 		settings: {
		// 			slidesToShow: 1,
		// 			dots: true,
		// 			arrows: false,
		// 			speed: 300,
		// 			infinite: false,
		// 			slidesToScroll: 1,
		// 		},
		// 	},
		// ],
		afterChange: current => {
			switch (current) {
				case 0:
					setIsFirstSlide(false)
					break
				case 1:
					setIsFirstSlide(true)
					break
				case 6:
					setIsLastSlide(false)
					break
				default:
					setIsFirstSlide(true)
					setIsLastSlide(true)
			}
		},
	}
	return (
		<div className=" after-yopro-second relative z-40 mx-auto mb-12 max-w-[1400px] lg:mt-24 ">
			<h2 className="mt-6 mb-4 text-center font-FuturaBold text-[36px] uppercase text-white lg:text-[58px]">Bevor du absendest</h2>
			<p className="mx-auto mb-6 text-center text-[18px] leading-[22px] text-white lg:max-w-[100%] lg:text-[22px] lg:leading-[37px]">
				Zum Schluss haben wir noch ein paar Fragen. Deine Antworten werden anonym behandelt.
			</p>
			<div className="mx-auto max-w-[800px] overflow-hidden bg-white px-2 py-4 lg:px-0 lg:py-12">
				<Slider ref={(c: any) => setSlider(c)} {...settings}>
					<div className="py-1 px-6">
						<MafoQuestionOne {...{ validation, formElementChanged }} />
					</div>
					<div className="py-1 px-6">
						<MafoQuestionTwo {...{ validation, formElementChanged }} />
					</div>
					<div className="py-1 px-6">
						<MafoQuestionThree {...{ validation, formElementChanged }} />
					</div>
					<div className="py-1 px-6">
						<MafoQuestionFour {...{ validation, formElementChanged }} />
					</div>
					<div className="py-1 px-6">
						<MafoQuestionFive {...{ validation, formElementChanged }} />
					</div>
					<div className="py-1 px-6">
						<MafoQuestionSix {...{ validation, formElementChanged }} />
					</div>
					<div className="py-1 px-6">
						<MAfoQuestionSeven {...{ validation, formElementChanged }} />
					</div>
				</Slider>
				<div
					className={
						"mx-auto mt-12 grid max-w-[100%] cursor-pointer items-center gap-4 px-6 text-[22px] lg:text-[32px] " + (!isFirstSlide || !isLastSlide ? "grid-cols-1" : "grid-cols-2")
					}
				>
					{isFirstSlide ? (
						<div className={!isLastSlide ? "justify-self-center" : "justify-self-end"}>
							<a
								className="btn-red w-[140px] max-w-[65%] font-FuturaBold text-white"
								onClick={() => {
									previous()
								}}
							>
								Zurück
							</a>
						</div>
					) : (
						<></>
					)}

					{isLastSlide && (
						<div className={isLastSlide && isFirstSlide ? "justify-self-start" : "justify-self-center"}>
							<a
								className="btn-red w-[140px] max-w-[65%] font-FuturaBold text-white"
								onClick={() => {
									next()
								}}
							>
								Weiter
							</a>
						</div>
					)}
				</div>
			</div>
			<div className="mb-12 lg:mb-12">
				{validation["invalid_question1"] ? (
					<p className="mx-auto  mb-6 mt-6 max-w-[80%] rounded-[5px] bg-white py-2 text-center  text-red lg:max-w-[50%]">Bitte Frage 1 beantworten.</p>
				) : (
					""
				)}
			</div>
		</div>
	)
}

export default Mafo
