import axios from "axios"
const domains = {
	"gratis.yopro.de": {
		apibase: "/",
	},
	"www.yopro-gratis-testen.de": {
		apibase: "/",
	},
	"prod.fe.danone-yopro-frontend.campaign-loyalty.com": {
		apibase: "/",
	},
}
export const getEnvConfig = () => {
	return domains[window.location.host] || { apibase: "/inte/" }
}
export async function checkOrderCount() {
	const env = getEnvConfig()
	return await axios
		.get(env.apibase + "checkOrder.php?cv=" + Date.now())
		.then(res => {
			console.log(res)
			let digits = Array.from(String(res.data.ordersLeft))
			const arrOfNums = digits.map(str => Number(str))
			return digits
		})
		.catch(error => {
			return error.response.status
		})
}
