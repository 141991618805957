import { useEffect, useState } from "react"
import { checkOrderCount } from "../env"
const RefundCounter = () => {
	const [orderDigitsSplit, setorderDigitsSplit] = useState([0, 0, 0, 0, 0])

	async function checkOrders() {
		setorderDigitsSplit(await checkOrderCount())
	}
	useEffect(() => {
		checkOrders()
	}, [])

	return (
		<div className="container mx-auto mt-6 mb-4 max-w-[1200px] text-center md:mt-12">
			<div className="mx-auto flex justify-center gap-2 self-center text-white lg:drop-shadow-[0_4px_27px_rgba(0,0,0,0.25)]">
				{orderDigitsSplit.map((item, index) => (
					<div key={index}>
						<span className="refund-item rounded-lg bg-red p-4  py-2 font-FuturaBold text-[42px]">{item}</span>
					</div>
				))}
			</div>
		</div>
	)
}

export default RefundCounter
