import * as React from "react"
import { useAutoAnimate } from "@formkit/auto-animate/react"

interface InputProps {
	props?: any
	className?: string | null
	label?: string | null
	errMsg?: string
	onClick?: any
	showLeftCount?: boolean
	hasError?: boolean
	suffix?: string
}

const ElemInput: React.FC<InputProps> = ({ className, props, label, onClick, errMsg, showLeftCount, hasError, suffix }) => {
	const [animationRef] = useAutoAnimate<HTMLDivElement>()
	return (
		<label className={"relative block " + (className || "")}>
			<span className={"pointer-events-none mt-1 hidden pb-1 pt-1 pl-3 text-white lg:block"}>{props.value !== "" ? label : ""}</span>
			{props.value && <span className={"pointer-events-none mt-1 block  pb-1 pt-1 pl-3 text-white lg:hidden"}>{label}</span>}
			<input
				before={props.placeholder}
				className={
					(props.type === "date" ? "mb-2 uppercase text-black" : " peer ") +
					"mb-2 w-full appearance-none bg-white py-3 px-4 text-black shadow-inner outline-0" +
					(hasError ? "  js-formerror lg:mt-0" : "") +
					(props.type === "date" ? (props.value !== "" ? "relative mb-2 py-2 before:hidden lg:py-3" : "") : "relative mb-2 py-2 lg:py-3")
				}
				onClick={onClick}
				{...props}
			/>
			{suffix && <div className={"absolute bottom-12 right-3 text-lightgray" + (hasError ? " bottom-12" : " bottom-4")}>{suffix}</div>}
			{showLeftCount && props.maxLength && (
				<div className="absolute top-6 right-3 text-lightgray">
					{props.value.length}/{props.maxLength}
				</div>
			)}
			<div ref={animationRef}>
				{hasError && <div className="formErrorMsg mb-3 inline-block rounded-[10px] bg-whiteLight75 px-2 py-1 text-[12px] font-bold text-red lg:mb-2">{errMsg}</div>}
			</div>
		</label>
	)
}

export default ElemInput

// https://play.tailwindcss.com/tKldzVn3U1
