import { useContext } from "react"
import * as React from "react"
import { Context } from "../../../store/store"
import ElemCheckboxMafo from "../../elements/CheckboxMafo"

interface MafoQuestionProps {
	formElementChanged: Function
	validation: Object
}
const MafoQuestionTwo: React.FC<MafoQuestionProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<>
			<h3 className="mb-6 text-left font-FuturaBold text-[17px] text-black lg:mb-12 lg:text-center lg:text-[24px]">
				Wie bist du auf YoPRO aufmerksam geworden? (Mehrfachantwort möglich)
			</h3>
			<div className="mx-auto grid grid-cols-1 gap-2 text-left lg:max-w-[75%] lg:grid-cols-2">
				<ElemCheckboxMafo
					label={<>Am Regal</>}
					props={{
						type: "checkbox",
						name: "question2",
						value: "a",
						onClick: e => formElementChanged(e, "question2"),
					}}
					className=" pb-2 text-green"
				/>
				<ElemCheckboxMafo
					label={<>Markt Prospekt</>}
					props={{
						type: "checkbox",
						name: "question2",
						value: "b",
						onClick: e => formElementChanged(e, "question2"),
					}}
					className="pb-2 text-green"
				/>
				<ElemCheckboxMafo
					label={<>Anzeigen</>}
					props={{
						type: "checkbox",
						name: "question2",
						value: "c",
						onClick: e => formElementChanged(e, "question2"),
					}}
					className=" pb-2 text-green"
				/>
				<ElemCheckboxMafo
					label={<>Social Media</>}
					props={{
						type: "checkbox",
						name: "question2",
						value: "d",
						onClick: e => formElementChanged(e, "question2"),
					}}
					className=" pb-2 text-green"
				/>
				<ElemCheckboxMafo
					label={<>Influencer</>}
					props={{
						type: "checkbox",
						name: "question2",
						value: "e",
						onClick: e => formElementChanged(e, "question2"),
					}}
					className=" pb-2 text-green"
				/>
				<ElemCheckboxMafo
					label={<>Online Video</>}
					props={{
						type: "checkbox",
						name: "question2",
						value: "f",
						onClick: e => formElementChanged(e, "question2"),
					}}
					className=" pb-2 text-green"
				/>
				<ElemCheckboxMafo
					label={<>Plakate</>}
					props={{
						type: "checkbox",
						name: "question2",
						value: "g",
						onClick: e => formElementChanged(e, "question2"),
					}}
					className=" pb-2 text-green"
				/>
				<ElemCheckboxMafo
					label={<>Durch die Gratis-Testen Aktion</>}
					props={{
						type: "checkbox",
						name: "question2",
						value: "h",
						onClick: e => formElementChanged(e, "question2"),
					}}
					className=" pb-2 text-green"
				/>
			</div>
		</>
	)
}

export default MafoQuestionTwo
