import { useContext } from "react"
import * as React from "react"
import { Context } from "../../../store/store"
import ElemCheckboxMafo from "../../elements/CheckboxMafo"

interface MafoQuestionProps {
	formElementChanged: Function
	validation: Object
}
const MafoQuestionFive: React.FC<MafoQuestionProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<>
			<h3 className="mb-6 text-left font-FuturaBold text-[17px] text-black lg:mb-12 lg:text-center lg:text-[24px]">Wie oft macht du Sport</h3>
			<div className="mx-auto grid grid-cols-1 gap-2 text-left lg:max-w-[75%] lg:grid-cols-2">
				<ElemCheckboxMafo
					label={<>Selten</>}
					props={{
						type: "radio",
						name: "question5",
						value: "a",
						onClick: e => formElementChanged(e, "question5"),
					}}
					hasError={validation["invalid_question5"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question5_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>1x pro Woche</>}
					props={{
						type: "radio",
						name: "question5",
						value: "b",
						onClick: e => formElementChanged(e, "question5"),
					}}
					hasError={validation["invalid_question5"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question5_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>2-3x pro Woche</>}
					props={{
						type: "radio",
						name: "question5",
						value: "c",
						onClick: e => formElementChanged(e, "question5"),
					}}
					hasError={validation["invalid_question5"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question5_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>4-5x pro Woche</>}
					props={{
						type: "radio",
						name: "question5",
						value: "d",
						onClick: e => formElementChanged(e, "question5"),
					}}
					hasError={validation["invalid_question5"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question5_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>mehr als 5x pro Woche</>}
					props={{
						type: "radio",
						name: "question5",
						value: "e",
						onClick: e => formElementChanged(e, "question5"),
					}}
					hasError={validation["invalid_question5"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question5_errMsg"] || ""}
				/>
			</div>
		</>
	)
}

export default MafoQuestionFive
