import React, { createContext, useReducer } from "react"
import Reducer from "./reducer"

const initialState = {
	salutation: "4",
	firstname: "",
	surname: "",
	email: "",
	terms: "",
	receiptReference: null,
	receiptFileName: null,
	accountHolder: "",
	purchaseDate: "",
	purchaseAmount: "",
	iban: "",
	bic: "",
	question1: [],
	question2: [],
	question3: "",
	question4: "",
	question5: "",
	question6: [],
	question7: "",
	optInAge: "",
	mailing: "",
	dritte: "",
}

const Store = ({ children }) => {
	const [globalState, dispatch] = useReducer(Reducer, initialState)
	return <Context.Provider value={{ globalState, dispatch }}>{children}</Context.Provider>
}
export const Context = createContext<{
	globalState
	dispatch: React.Dispatch<any>
}>({
	globalState: initialState,
	dispatch: () => {},
})
export default Store
