import { useContext } from "react"
import * as React from "react"
import { Context } from "../../../store/store"
import ElemCheckboxMafo from "../../elements/CheckboxMafo"

interface MafoQuestionProps {
	formElementChanged: Function
	validation: Object
}
const MafoQuestionSix: React.FC<MafoQuestionProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<>
			<h3 className="mb-6 text-left font-FuturaBold text-[17px] text-black lg:mb-12 lg:text-center lg:text-[24px]">
				Welches YoPRO Produkt hast du bisher getestet? (Mehrfachantwort möglich)
			</h3>
			<div className="mx-auto grid grid-cols-1 gap-2 text-left lg:max-w-[75%] lg:grid-cols-2">
				<ElemCheckboxMafo
					label={<>Drink Erdbeer-Himbeer Geschmack</>}
					props={{
						type: "checkbox",
						name: "question6",
						value: "a",
						onClick: e => formElementChanged(e, "question6"),
					}}
					hasError={validation["invalid_question6"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question6_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Drink Stracciatella Geschmack</>}
					props={{
						type: "checkbox",
						name: "question6",
						value: "b",
						onClick: e => formElementChanged(e, "question6"),
					}}
					hasError={validation["invalid_question6"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question6_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Cremiger Skyr Erdbeere</>}
					props={{
						type: "checkbox",
						name: "question6",
						value: "c",
						onClick: e => formElementChanged(e, "question6"),
					}}
					hasError={validation["invalid_question6"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question6_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Cremiger Skyr Zitrone</>}
					props={{
						type: "checkbox",
						name: "question6",
						value: "d",
						onClick: e => formElementChanged(e, "question6"),
					}}
					hasError={validation["invalid_question6"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question6_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Cremiger Skyr Erdnussbutter-Banane Geschmack</>}
					props={{
						type: "checkbox",
						name: "question6",
						value: "e",
						onClick: e => formElementChanged(e, "question6"),
					}}
					hasError={validation["invalid_question6"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question6_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Cremiger Skyr Stracciatella Geschmack</>}
					props={{
						type: "checkbox",
						name: "question6",
						value: "f",
						onClick: e => formElementChanged(e, "question6"),
					}}
					hasError={validation["invalid_question6"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question6_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Pouch Mango</>}
					props={{
						type: "checkbox",
						name: "question6",
						value: "g",
						onClick: e => formElementChanged(e, "question6"),
					}}
					hasError={validation["invalid_question6"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question6_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Pouch Banane</>}
					props={{
						type: "checkbox",
						name: "question6",
						value: "h",
						onClick: e => formElementChanged(e, "question6"),
					}}
					hasError={validation["invalid_question6"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question6_errMsg"] || ""}
				/>
			</div>
		</>
	)
}

export default MafoQuestionSix
