import { Link } from "react-router-dom"
import React from "react"

function Footer() {
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}

	return (
		<footer className="relative mt-24  items-center bg-black  lg:z-[600]">
			<section>
				<div className="mx-auto mt-12 mb-24  max-w-[1400px] lg:mb-12">
					<h2 className="mb-6 px-4 text-center font-FuturaBold text-[24px] uppercase text-white lg:px-0 lg:text-[42px]">Folge uns auf:</h2>
					<div className="mx-auto mb-12  grid w-[80%] grid-cols-5 items-center justify-items-center self-center lg:mb-24 lg:w-[40%] lg:grid-cols-5">
						<a href="https://www.pinterest.de/yopro_de/" target="_blank" className="pointer underline">
							<img src={process.env.PUBLIC_URL + "/images/danone/social/pinterest.png"} alt="Instagram" className="-mt-[4px] w-[40px] lg:-mt-[12px] lg:w-[75px]" />
						</a>
						<a href="https://www.instagram.com/yopro.de/" target="_blank" className="pointer underline">
							<img src={process.env.PUBLIC_URL + "/images/danone/social/instagram.png"} alt="Instagram" className="w-[50px] lg:w-[100px]" />
						</a>
						<a href="https://www.facebook.com/profile.php?id=61559613796257" target="_blank" className="pointer underline">
							<img src={process.env.PUBLIC_URL + "/images/danone/social/facebook.png"} alt="Instagram" className="-mt-[4px] w-[39px] lg:-mt-[10px] lg:w-[70px]" />
						</a>
						<a href="https://www.tiktok.com/@yopro_de?lang=de-DE" target="_blank" className="pointer underline">
							<img src={process.env.PUBLIC_URL + "/images/danone/social/tiktok.png"} alt="Instagram" className="w-[50px] lg:w-[100px]" />
						</a>
						<a href=" https://www.youtube.com/@YoPRODEUTSCHLAND" target="_blank" className="pointer underline">
							<img src={process.env.PUBLIC_URL + "/images/danone/social/youtube.png"} alt="Instagram" className="w-[50px] lg:w-[100px]" />
						</a>
					</div>
				</div>
			</section>
			<section className="-mt-24 grid w-full items-end bg-cover bg-bottom lg:mt-0">
				<div className=" mt-8 flex flex-col items-center justify-center py-4  font-FuturaBold text-[22px] lg:mt-0 lg:flex-col">
					<Link to={"/teilnahmebedingungen"} className="mb-2 text-white" onClick={() => track("Teilnahmebedingungen")}>
						Teilnahmebedingungen
					</Link>
					<Link to={"/faq"} className="mb-2 text-white " onClick={() => track("FAQ")}>
						FAQS
					</Link>
					<Link to={"/datenschutz"} className="mb-2 text-white" onClick={() => track("FAQ")}>
						Datenschutzbestimmungen
					</Link>
					<Link to={"/impressum"} className="mb-2 text-white " onClick={() => track("Impressum")}>
						Impressum
					</Link>
				</div>
			</section>
		</footer>
	)
}

export default Footer
