import { useAutoAnimate } from "@formkit/auto-animate/react"
import React from "react"

interface CheckboxProps {
	props?: any
	className?: string | null
	label?: any
	errMsg?: string
	hasError?: boolean
}

const ElemCheckboxMafo: React.FC<CheckboxProps> = ({ className, props, label, errMsg, hasError }) => {
	const [animationRef] = useAutoAnimate<HTMLDivElement>()
	return (
		<label className={"relative block cursor-pointer " + (className || "")}>
			<div className="flex">
				<input className={"peer absolute hidden h-1 w-1 " + (hasError ? " js-formerror " : "")} {...props} />
				<span className="flex h-[32px] w-[32px] flex-shrink-0 peer-checked:hidden">
					<img src={process.env.PUBLIC_URL + "/images/danone/unchecked-mafo.svg"} alt="check" className="w-[24px]" />
				</span>
				<span className="block flex hidden h-[32px] w-[32px] flex-shrink-0 peer-checked:flex">
					<img src={process.env.PUBLIC_URL + "/images/danone/checked-mafo.svg"} alt="check" className="w-[24px]" />
				</span>
				<div>
					<div className="pl-2 text-[18px] text-black lg:text-[20px]">{label}</div>
					<div ref={animationRef}>{hasError && <div className="formErrorMsg js-formerror pl-2 pt-2 font-bold text-red">{errMsg}</div>}</div>
				</div>
			</div>
		</label>
	)
}

export default ElemCheckboxMafo
