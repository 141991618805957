interface PopupProps {
	close: any
}
const Popup: React.FC<PopupProps> = ({ close, children }) => {
	return (
		<section className="fixed left-0 top-0 z-[2000] mt-12 h-full w-full bg-black/30 p-12 pt-12 transition duration-150 ease-in-out lg:pt-24">
			<div className="mx-auto h-[95%] w-[95%] bg-white p-8 text-center md:w-[950px]  lg:h-full">
				<img
					src={process.env.PUBLIC_URL + "/images/icon-close.svg"}
					alt="Menu"
					loading="lazy"
					width="60"
					height="73"
					className=" left-[50%] top-4 z-10 mx-auto mb-2 -mt-[58px] w-[60px] cursor-pointer rounded-full bg-red p-2 "
					onClick={close}
				/>
				{children}
			</div>
		</section>
	)
}

export default Popup
