import { useContext } from "react"
import * as React from "react"
import { Context } from "../../../store/store"
import ElemCheckboxMafo from "../../elements/CheckboxMafo"

interface MafoQuestionProps {
	formElementChanged: Function
	validation: Object
}
const MafoQuestionThree: React.FC<MafoQuestionProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<>
			<h3 className="mb-6 text-left font-FuturaBold text-[17px] text-black lg:mb-12 lg:text-center lg:text-[24px]">Wann hast du YoPRO konsumiert?</h3>
			<div className="mx-auto grid grid-cols-1 gap-2 text-left lg:max-w-[75%] lg:grid-cols-2">
				<ElemCheckboxMafo
					label={<>Frühstück</>}
					props={{
						type: "radio",
						name: "question3",
						value: "a",
						onClick: e => formElementChanged(e, "question3"),
					}}
					hasError={validation["invalid_question3"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question3_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Vormittags-Snack</>}
					props={{
						type: "radio",
						name: "question3",
						value: "b",
						onClick: e => formElementChanged(e, "question3"),
					}}
					hasError={validation["invalid_question3"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question3_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Nachmittags-Snack</>}
					props={{
						type: "radio",
						name: "question3",
						value: "c",
						onClick: e => formElementChanged(e, "question3"),
					}}
					hasError={validation["invalid_question3"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question3_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Dessert</>}
					props={{
						type: "radio",
						name: "question3",
						value: "d",
						onClick: e => formElementChanged(e, "question3"),
					}}
					hasError={validation["invalid_question3"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question3_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Direkt nach dem Sport</>}
					props={{
						type: "radio",
						name: "question3",
						value: "e",
						onClick: e => formElementChanged(e, "question3"),
					}}
					hasError={validation["invalid_question3"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question3_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Direkt vor dem Sport</>}
					props={{
						type: "radio",
						name: "question3",
						value: "f",
						onClick: e => formElementChanged(e, "question3"),
					}}
					hasError={validation["invalid_question3"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question3_errMsg"] || ""}
				/>
			</div>
		</>
	)
}

export default MafoQuestionThree
