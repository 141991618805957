import React, { useEffect } from "react"
import DocumentMeta from "react-document-meta"
import Form from "../components/Forms/Form"
import Sogehts from "../components/home/Sogehts"
import Header from "../components/Header"
import Hero from "../components/home/Hero"
import Info from "../components/Info"

const Home: React.FC = () => {
	// SEO Meta Tags

	const meta = {
		title: "Hol dir dein gratis Protein",
		description:
			"Hol dir dein gratis Protein, Danone, YoPRO, Protein, Gratis-Testen-Aktion, gratis, testen, kostenlos, Skyr Style, Drinks, Pouch, Erdbeere, Himbeere, Banane, Mango, Stracciatella, Zitrone",
		meta: {
			name: {
				robots: "noindex, nofollow",
			},
		},
	}
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])
	return (
		<DocumentMeta {...meta}>
			<div>
				<Header />
				<Hero />
				<Sogehts type="start" />
			</div>
			<Form />
			<Info />
		</DocumentMeta>
	)
}
export default Home
