import React, { useEffect } from "react"
import DocumentMeta from "react-document-meta"
import { useNavigate, Link } from "react-router-dom"
import AccordionItem from "../components/elements/AccordionItem"
import Header from "../components/Header"

function FAQ() {
	const navigate = useNavigate()

	// SEO Meta Tags

	const meta = {
		// title: "Mit merci zum Muttertag filmreif Danke sagen!",
		// canonical: "https://arvato-hawaiian-tropic-2023.de/",
		// meta: {
		// 	name: {
		// 		robots: "noindex, nofollow",
		// 	},
		// },
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<DocumentMeta {...meta}>
			<div className="bg-gradient-to-r from-[#F8DE4B]  to-[#EFBB27]">
				<Header type="logo" />
				<div className="container mx-auto max-w-[800px] py-8 px-4 ">
					<h2 className="h2 mb-8 mt-32 text-center font-FuturaBold text-2xl text-black lg:mt-24">FAQ </h2>
					<h3 className="mb-6 text-center font-FuturaBold text-[32px] text-black">1. Allgemeine Informationen</h3>
					<AccordionItem headline="Wer ist der Veranstalter der Aktion?" num="1.">
						<p className="mb-2 text-black">Veranstalter ist</p>
						<p className="mb-2 text-black">
							Danone Deutschland GmbH <br />
							Am Hauptbahnhof 18 <br />
							60329 Frankfurt am Main
						</p>
						<p className="mb-2 text-black">(im Folgenden „Veranstalter“ genannt)</p>
						<p className="mb-2 text-black">Im Auftrag der Danone Deutschland GmbH ist die direct services Gütersloh GmbH bei der Durchführung der Aktion unterstützend tätig.</p>
					</AccordionItem>
					<AccordionItem headline="Wer kann an der Aktion teilnehmen?" num="2.">
						<>
							<p className="mb-2 text-black">
								Es dürfen nur Personen teilnehmen, die einen Wohnsitz in Deutschland haben und mindestens 18 Jahre alt sind. Es ist nicht erlaubt im Namen von anderen Personen
								(z.B. Freunden oder Verwandten) teilzunehmen oder Dritte mit der Teilnahme zu beauftragen. Mitarbeitende des Veranstalters und Personen, die an der Konzeption oder
								Durchführung beteiligt sind, dürfen nicht teilnehmen. Das gilt auch für deren Angehörige. Personen können auch nachträglich ausgeschlossen werden, wenn sich erst zu
								einem späteren Zeitpunkt herausstellt, dass sie nicht zur Teilnahme berechtigt waren.
							</p>
						</>
					</AccordionItem>
					<AccordionItem headline="Wie lange kann ich an der Aktion teilnehmen?" num="3.">
						<p className="mb-6 text-black">
							Der Aktionszeitraum beginnt am 01.07.2024 und endet am 30.09.2024. Kassenbelege können bis zum 14.10.2024, 23:59 Uhr auf der Aktionsseite hochgeladen werden. Ein
							Teilnahmevorgang muss innerhalb dieses Zeitraums vollständig abgeschlossen werden, um als gültige Teilnahme akzeptiert zu werden. Über die erfolgreiche Teilnahme wird
							der Teilnehmer auf der Aktionsseite informiert.
						</p>
						<p className="mb-6 text-black">
							Der Veranstalter kann das Gewinnspiel jederzeit pausieren oder vorzeitig beenden, wenn dies aus technischen, organisatorischen oder rechtlichen Gründen notwendig ist.
							Insbesondere kann die Aktion vom Veranstalter vorzeitig beendet werden, wenn die Teilnehmerzahl von 50.000 erreicht ist. Der Veranstalter ist berechtigt den
							Teilnahmezeitraum jederzeit zu verlängern.
						</p>
						<p className="mb-6 text-black">
							Änderungen am Teilnahmezeitraum werden entweder auf der Aktionswebsite, der Unternehmenswebsite oder auf einem Social Media Profil des Veranstalters veröffentlicht.
						</p>
					</AccordionItem>
					<AccordionItem headline="Welche Produkte sind für die Teilnahme berechtigt?" num="4.">
						<p className="mb-6 text-black">Alle YoPRO Produkte, die in Deutschland gekauft wurden. Als Teilnahmevoraussetzung gilt der Kauf von mindestens einem Produkt.</p>
					</AccordionItem>
					<AccordionItem headline="Wie oft kann eine Person teilnehmen?" num="5.">
						<p className="mb-2 text-black">
							Sofern die Teilnahmevoraussetzungen (vgl. Abschnitt „Welche Teilnahmevoraussetzungen gib es?“) erfüllt sind, darf eine Person einmal an der Aktion teilnehmen. Beim
							Kauf mehrerer Produkte auf einem Beleg wird nur ein Produkt mit dem günstigsten Kaufpreis erstattet.
						</p>
					</AccordionItem>
					<h3 className="mb-6 mt-24 text-center font-FuturaBold text-[32px] text-black">2. Durchführung und Abwicklung</h3>
					<AccordionItem headline="Wie kann ich an der Aktion teilnehmen?" num="6.">
						<p className="mb-2 text-black">
							Die Teilnahme erfolgt nur auf der Aktionswebsite unter{" "}
							<a className="underline" href="https://www.yopro.de/gratis" target="_blank">
								www.yopro.de/gratis
							</a>
							. Auf dieser Website müssen alle für die Teilnahme erforderlichen Daten von der teilnehmenden Person angegeben werden. Eine Teilnahme über andere Kanäle (z.B. E-Mail,
							SMS, Post, Telefon) ist nicht möglich.
						</p>
						<p className="mb-2 text-black">
							Als Voraussetzung für eine gültige Teilnahme gilt der Kauf von mindestens einem Aktionsprodukt (siehe Abschnitt: „Welche Aktionsprodukte werden für die Teilnahme
							akzeptiert?“). Der Kauf muss durch ein Foto des Kassenbeleges nachgewiesen werden. Das Foto muss auf der Aktionswebsite hochgeladen werden. Dabei müssen die
							Bezeichnung und der Kaufpreis des Aktionsproduktes deutlich auf dem Beleg sichtbar sein. Der Kauf kann bei einem beliebigen Händler erfolgen, der ein Aktionsprodukt
							im Sortiment führt. Ein Mindesteinkaufswert wird nicht vorausgesetzt, weder für die Aktionsprodukte noch für den gesamten Einkauf. Die Aktionsprodukte müssen im
							Zeitraum vom 01.07.2024 bis einschließlich 30.09.2024 gekauft worden sein. Der Kaufzeitpunkt muss auf dem Kassenbeleg sichtbar sein.
						</p>
					</AccordionItem>
					<AccordionItem headline="Welche Pflichtangaben müssen auf dem Kaufbeleg zu sehen sein?" num="7.">
						<p className="text-black">
							Auf dem Foto müssen alle relevanten Teile des Kassenbelegs gut sichtbar sein. Screenshots von digitalen Kaufbelegen sind möglich. Neben den Angaben, die als
							Teilnahmevoraussetzung erforderlich sind, müssen zusätzlich immer der Name des Händlers, der Kaufzeitpunkt (Datum und im Idealfall auch die Uhrzeit) und die
							Gesamtsumme des Kassenbelegs enthalten sein. Es ist nicht erlaubt denselben Kassenbeleg für mehrere Teilnahmen zu nutzen. Daten, die für die Teilnahme nicht
							erforderlich sind (z.B. sonstige gekaufte Produkte) können geschwärzt werden. Alle Kassenbelege werden automatisiert und nach Bedarf auch manuell überprüft. Bei
							Zweifel an der Echtheit des Belegs sowie bei nicht-lesbaren oder fehlenden Informationen ist eine Teilnahme ausgeschlossen.
						</p>
					</AccordionItem>
					<AccordionItem headline="Wie lädt man das Foto vom Kassenbon richtig hoch?" num="8.">
						<p className="text-black">
							Es können beim Upload nur Fotos im Format JPG, PNG und PDF und einer maximalen Größe von 8 MB pro Foto hochgeladen werden. Auf dem Kassenbon müssen die folgenden
							Daten erkennbar sein: Kaufdatum, Kaufpreis des Produktes, Bonsumme, Einkaufsstätte sowie das Produkt. Auf dem Kassenbon abgebildete personenbezogene Daten (z. B.
							Namen von Beschäftigten) sind nicht abzulichten oder vorab zu schwärzen oder in sonstiger Weise unkenntlich zu machen. Unvollständige sowie verspätete Angaben können
							leider nicht berücksichtigt werden.
						</p>
					</AccordionItem>
					<AccordionItem headline="Wo findet man die IBAN/BIC?" num="9.">
						<p className=" text-black">
							Die persönliche IBAN-Nummer und den BIC findet man auf der Vorder- und/oder Rückseite der Bankkarte sowie im Online-Banking. Die Zahlenfolge der IBAN für Deutschland
							beginnt stets mit DE und enthält 22 Zeichen.
						</p>
					</AccordionItem>
					<h3 className="mb-6 mt-24 text-center font-FuturaBold text-[32px] text-black">3. Nach der Teilnahme</h3>
					<AccordionItem headline="Welchen Cashback Betrag erhalte ich?" num="10.">
						<p className=" text-black">
							Es wird jeweils der Kaufpreis für ein YoPRO Produkt (exkl. Pfand) auf dem Kassenbeleg erstattet. Wenn 50.000 Teilnehmer registriert sind, werden keine weiteren
							Einkäufe erstattet.
						</p>
					</AccordionItem>
					<AccordionItem headline="Wie erfolgt die Einlösung des Cashbacks?" num="11.">
						<p className="mb-6  text-black">
							Um den Kaufpreis erstattet zu bekommen, muss ein Kassenbeleg-Foto des Folgekaufs auf der Aktionswebsite hochgeladen werden und eine Pflichtfrage zum Produkt
							beantwortet werden.
						</p>
						<p className="mb-6  text-black">
							Um den Kaufpreis erstattet zu bekommen, muss ein Kassenbeleg-Foto des Folgekaufs auf der Aktionswebsite hochgeladen werden und eine Pflichtfrage zum Produkt
							beantwortet werden. Der Veranstalter kann die Auszahlung bei begründeten Zweifeln an der Echtheit des Kassenbelegs oder bei sonstigen Verstößen gegen die Regeln der
							Aktion verweigern. Es findet ggf. eine zusätzliche manuelle Überprüfung der Teilnahmedaten und des Kassenbelegs statt. Die Auszahlung erfolgt als Überweisung in Euro
							an das persönliche Girokonto der teilnehmenden Person. Hierfür muss die IBAN des eigenen Kontos angegeben werden. Es erfolgt keine Auszahlung an Konten von anderen
							Personen oder an Konten bei ausländischen Banken oder in Fremdwährungen. Die teilnehmende Person ist für die korrekte Eingabe der IBAN verantwortlich. Wenn
							versehentlich eine falsche IBAN angegeben wurde, ist keine nachträgliche Korrektur möglich. In diesem Fall verfällt der Anspruch auf die Cashback-Auszahlung. Sofern
							alle Teilnahmevoraussetzungen erfüllt sind, wird der Kaufpreis innerhalb eines Zeitraums von vier Wochen auf das angegebene Konto überwiesen.
						</p>
					</AccordionItem>
					<h3 className="mb-6 mt-24 text-center font-FuturaBold text-[32px] text-black">4. Sonstiges</h3>
					<AccordionItem headline="Welche persönlichen Daten werden für die Teilnahme benötigt?" num="12.">
						<p className="mb-6  text-black">
							Für die Teilnahme müssen die persönliche E-Mail-Adresse und der vollständige Name angegeben werden. Diese Informationen werden benötigt, um die Aktion sicher
							abwickeln zu können.
						</p>
						<p className="mb-6  text-black">
							Um eine Geld-zurück-Auszahlung in Anspruch zu nehmen, muss insbesondere auch die IBAN des eigenen Girokontos mitgeteilt werden. Diese Angabe ist freiwillig. Ohne
							angegebene IBAN kann jedoch keine Geld-zurück-Auszahlung stattfinden.
						</p>
						<p className="mb-6  text-black">Es muss eine Pflichtfrage zum Produkt beantwortet werden.</p>
						<p className="mb-6  text-black">
							Personenbezogen Daten, die sich möglicherweise auf einem Kaufbeleg befinden und für Teilnahme am Gewinnspiel nicht erforderlich sind, sollten geschwärzt werden (z.B.
							Adressdaten).
						</p>
					</AccordionItem>
					<AccordionItem headline="Wie werden Daten im Rahmen der Aktion verarbeitet?" num="13.">
						<p className="mb-6  text-black">
							Für die Abwicklung der Aktion ist es notwendig, dass personenbezogen Daten verarbeitet werden (z.B. E-Mail-Adresse und Name). Diese Daten werden vom Veranstalter und
							Dienstleistern des Veranstalters mit äußerster Sorgfalt und gemäß den gültigen Datenschutzbestimmungen verarbeitet. Details sind in der Datenschutzerklärung
							beschrieben.
						</p>
						<p className="mb-6  text-black">
							Alle Teilnahmedaten werden für die gesamte Dauer der Aktion gespeichert und spätestens 6 Wochen nach Ende der Aktion gelöscht, sofern keine besondere Einwilligung in
							eine längerfristige Speicherung erfolgte oder eine längerfristige Speicherung aus rechtlichen Gründen erforderlich ist. Eine längerfristige Speicherung erfolgt
							beispielsweise, falls die freiwillige Einwilligung für den Empfang eines Newsletters gegeben wurde oder im Falle der Nachweisbarkeit von Cashback-Transaktionen. Dabei
							werden nur die tatsächlich benötigten Daten gespeichert. Alle nicht benötigten Daten werden auch in diesen Fällen gelöscht.
						</p>
						<p className="mb-6  text-black">
							Darüber hinaus können statistische Daten gespeichert werden (z.B. für Nutzungsstatistiken). Statistische Daten werden nur in vollständig anonymisierter Form
							verarbeitet. Ein Rückschluss auf einzelne Personen ist dabei ausgeschlossen.{" "}
						</p>
					</AccordionItem>
					<AccordionItem headline="Werden Daten von teilnehmenden Personen veröffentlicht?" num="14.">
						<p className="mb-6  text-black">
							Der Veranstalter wird ohne Erlaubnis grundsätzlich keine Daten von teilnehmenden Personen veröffentlichen. Nur nach ausdrücklicher, vorheriger Einwilligung der
							teilnehmen Personen ist es möglich, dass beispielsweise die Gewinnernamen auf einer Website oder in einem Social Media Post genannt werden. Auf Wunsch erfolgt die
							Nennung dabei in anonymisierter Form.
						</p>
					</AccordionItem>
					<AccordionItem headline="Du hast keine Antwort auf deine Frage erhalten?" num="15.">
						<p className="mb-6  text-black">
							Das Service-Team ist per E-Mail unter{" "}
							<a href="mailto:info@yopro-gratis-testen.de " className="underline" target="_blank">
								info@yopro-gratis-testen.de
							</a>{" "}
							erreichbar.
						</p>
					</AccordionItem>
				</div>
				<div className="py-10 pt-5 text-center text-xl text-black">
					<Link to="/" className="btn btn-hero  mt-2 bg-white px-12 py-4 font-FuturaBold text-black">
						Zurück
					</Link>
				</div>
			</div>
		</DocumentMeta>
	)
}
export default FAQ
