import React, { useEffect } from "react"
import DocumentMeta from "react-document-meta"
import { useNavigate, Link } from "react-router-dom"
import Header from "../components/Header"

const Privacy: React.FC = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<DocumentMeta>
			<div>
				<div className="light">
					<Header type="logo" />
					<div className="mx-auto bg-gradient-to-r from-[#F8DE4B]  to-[#EFBB27] py-8 px-4 text-black">
						<div className="mx-auto max-w-[800px]">
							<h2 className=" mb-8 mt-32 text-center font-FuturaBold text-4xl text-black lg:mt-64">Hinweise zum Datenschutz</h2>
							<p className="mb-4">
								Für die Verarbeitung der personenbezogenen Daten im Rahmen der Cashback-Aktion ist die Danone Deutschland GmbH, Am Hauptbahnhof 18, 60329 Frankfurt am Main,
								Deutschland (nachfolgend „Danone“ genannt) verantwortlich. Die uns mitgeteilten Daten verarbeiten wir zur Durchführung der Aktion und insbesondere um die Auszahlung
								der Cashback-Beträge vornehmen zu können. (Rechtsgrundlage Art. 6 Abs. 1 lit. b) und f) DSGVO). Die Kontaktdaten des Datenschutzbeauftragten von Danone lauten M.
								Lindner (ebDSB DANONE) c/o intersoft consulting services AG, Beim Strohhause 17, 20097 Hamburg, MLindner@intersoft-consulting.de.
							</p>
							<p className="mb-4">
								Alle personenbezogenen Daten der Teilnehmer werden ohne deren Einwilligung weder an Dritte weitergegeben noch diesen zur Nutzung zur Verfügung gestellt. Bei der
								Abwicklung unserer Aktion und bei der Verifizierung eingereichter Daten wird Danone von dessen Auftragsdatenverarbeiter, der Agentur direct services Gütersloh GmbH,
								Reinhard-Mohn-Str. 300, 33333 Gütersloh, unterstützt, der die Daten auf Grundlage vertraglicher Vereinbarungen nur entsprechend der Weisungen von Danone verarbeitet
								Die Daten der Teilnehmer werden – vorbehaltlich bestehender längerfristiger gesetzlicher Aufbewahrungsfristen – spätestens 3 Monate nach Auszahlung des
								Cashback-Betrags gelöscht. Selbstverständlich können Sie über die bei Danone gespeicherten Daten jederzeit Auskunft verlangen und / oder der Verwendung Ihrer Daten
								jederzeit ganz oder teilweise mit Wirkung für die Zukunft gegenüber Danone widersprechen, indem Sie einfach eine E-Mail an{" "}
								<a href="mailto:kontakt@danone.de" className="underline" target="_blank">
									kontakt@danone.de
								</a>{" "}
								schicken. Darüber hinaus hat jeder Teilnehmer die nach der DSGVO vorgesehenen Rechte auf Widerruf einer erteilten Einwilligung, Auskunft, Berichtigung, Löschung,
								Einschränkung der Verarbeitung, Datenübertragbarkeit sowie Beschwerde bei der zuständigen Datenschutzaufsichtsbehörde. Danone weist darauf hin, dass für die
								Durchführung der Aktion die Bereitstellung von personenbezogenen Daten des Teilnehmers erforderlich ist. Die Nichtbereitstellung der Daten und/oder die
								Geltendmachung von Löschungs-, Einschränkungs- und/oder Widerrufs- bzw. Widerspruchsrechten vor Abschluss der Aktion kann daher dazu führen, dass der jeweilige
								Teilnehmer von der weiteren Aktionsteilnahme ausgeschlossen wird und kein Cashback mehr ausgezahlt werden kann.
							</p>
							<p className="mb-4">
								Des Weiteren kann Danone Ihre personenbezogenen Daten auf der Grundlage Ihrer im Teilnahmeformular erteilten Einwilligungen in Zielgruppen verarbeiten. Diese
								Einwilligungen sind freiwillig und müssen nicht zwingend für die Teilnahme an der Aktion erteilt werden.
							</p>
							<p className="mb-4">
								Wir nehmen, sofern Ihre Einwilligung erteilt wurde, Ihre pseudonymisierte E-Mail-Adresse in Zielgruppen auf. Kriterien sind zum Beispiel das ähnliche Webverhalten
								oder Interesse an bestimmten Produkten. Sowohl wir als auch die Drittparteien können dann diese Nutzergruppen für personalisierte Inhalte oder Werbung bereitstellen
								oder zur weiteren Personalisierung Ihres Profils nutzen. Die Datenverarbeitung erfolgt dann auf der Grundlage Ihrer Einwilligung gem. Art. 6 Abs. 1 lit. a) DSGVO.
							</p>
							<p className="mb-4">
								Wenn Sie uns Daten (wie etwa Ihre E-Mail-Adresse) bei der Teilnahme an einem Gewinnspiel, einer Cashback-Aktion, bei Anmeldung zu einem Newsletter oder bei der
								Registrierung für ein Kundekonto zur Verfügung stellen und zudem in den Datenaustausch mit Drittparteien eingewilligt haben, verwenden wir Ihre Daten, um
								Zielgruppen zu bilden und um diese mit personalisierter Werbung zu adressieren. Hierzu erzeugen wir zunächst ein digitales Abbild bzw. eine pseudonymisierte-Version
								Ihrer E-Mail-Adresse.
							</p>
							<p className="mb-4">
								Ihre E-Mail-Adresse können wir mit unseren aktuellen Drittparteien (einsehbar auf unserer Internetseite) in einem geschützten Daten-Raum zum Abgleich austauschen,
								die im gleichen Verfahren erstellt wurden. Sollten Sie mit derselben E-Mail-Adresse sowohl bei uns als auch bei einer unserer Drittparteien registriert sein, sind
								sowohl wir als auch die jeweilige Drittpartei aufgrund des Abgleichs in der Lage Sie als eigener Newsletter-Abonnent oder eigener registrierter Nutzer mit
								Kundenkonto zu identifizieren. Sie können diese Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen. Zur Information über die Datenverarbeitungen unserer
								Drittparteien und deren jeweiligen Verarbeitungszwecke stellen Ihnen diese jeweils eigene Datenschutzhinweise zur Verfügung.
							</p>
							<p className="mb-4">
								Sie können diese Einwilligungen jederzeit mit Wirkung für die Zukunft widerrufen. Bei Rückfragen wenden Sie sich gerne jederzeit an{" "}
								<a href="mailto:kontakt@danone.de" className="underline" target="_blank">
									kontakt@danone.de
								</a>{" "}
								.
							</p>
							<p className="mb-4">Wenn Sie weitere Informationen zum Datenschutz haben wollen, dann klicken Sie bitte hier:</p>
							<h3 className="h3 py-4">Ergänzung der Datenschutzerklärung auf den jeweiligen Brand-Webseiten unter „Datenübermittlung“: </h3>
							<p className="mb-4">Zielgruppen:</p>
							<p className="mb-4">
								Wir nehmen, sofern Ihre Einwilligung erteilt wurde, Ihre pseudonymisierte E-Mail-Adresse in Zielgruppen auf. Kriterien sind zum Beispiel das ähnliche Webverhalten
								oder Interesse an bestimmten Produkten. Sowohl wir als auch die Drittparteien können dann diese Nutzergruppen für personalisierte Inhalte oder Werbung bereitstellen
								oder zur weiteren Personalisierung Ihres Profils nutzen. Die Datenverarbeitung erfolgt dann auf der Grundlage Ihrer Einwilligung gem. Art. 6 Abs. 1 lit. a) DSGVO.
							</p>
							<p className="mb-4">
								Wenn Sie uns Daten (wie etwa Ihre E-Mail-Adresse) bei der Teilnahme an einem Gewinnspiel, bei Anmeldung zu einem Newsletter oder bei der Registrierung für ein
								Kundekonto zur Verfügung stellen und zudem in den Datenaustausch mit Drittparteien eingewilligt haben, verwenden wir Ihre Daten, um Zielgruppen zu bilden und um
								diese mit personalisierter Werbung zu adressieren. Hierzu erzeugen wir zunächst ein digitales Abbild bzw. eine pseudonymisierte-Version Ihrer E-Mail-Adresse.
							</p>
							<p className="mb-4">
								Ihre E-Mail-Adresse können wir mit unseren aktuellen Drittparteien (einsehbar auf unserer Internetseite) in einem geschützten Daten-Raum zum Abgleich austauschen,
								die im gleichen Verfahren erstellt wurden. Sollten Sie mit derselben E-Mail-Adresse sowohl bei uns als auch bei einer unserer Drittparteien registriert sein, sind
								sowohl wir als auch die jeweilige Drittpartei aufgrund des Abgleichs in der Lage Sie als eigener Newsletter-Abonnent oder eigener registrierter Nutzer mit
								Kundenkonto zu identifizieren. Sie können diese Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen. Zur Information über die Datenverarbeitungen unserer
								Drittparteien und deren jeweiligen Verarbeitungszwecke stellen Ihnen diese jeweils eigene Datenschutzhinweise zur Verfügung.
							</p>
							<h3 className="h3 py-4">Externer Datenschutzbeauftragter: </h3>
							<p className="mb-4">
								Herr Matthias Lindner
								<br />
								intersoft consulting services AG
								<br />
								Beim Strohhause 17, 20097 Hamburg
								<br />
								<a href="mailto:MLindner@intersoft-consulting.de" className="underline" target="_blank">
									MLindner@intersoft-consulting.de
								</a>
							</p>
						</div>
						<div className=" py-10 pt-5 text-center text-xl text-black">
							<Link to="/" className="btn btn-hero mt-8 rounded bg-white px-12 py-4 text-black">
								Zurück
							</Link>
						</div>
					</div>
				</div>
			</div>
		</DocumentMeta>
	)
}
export default Privacy
