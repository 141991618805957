import React, { useEffect } from "react"
import DocumentMeta from "react-document-meta"
import { useNavigate, Link } from "react-router-dom"
import RosaSection from "../components/elements/BeigeSection"
import Header from "../components/Header"
const Imprint: React.FC = () => {
	const navigate = useNavigate()

	// SEO Meta Tags

	const meta = {
		// title: "Mit merci zum Muttertag filmreif Danke sagen!",
		// canonical: "https://arvato-hawaiian-tropic-2023.de/",
		// meta: {
		// 	name: {
		// 		robots: "noindex, nofollow",
		// 	},
		// },
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<DocumentMeta {...meta}>
			<div className="bg-gradient-to-r from-[#F8DE4B]  to-[#EFBB27]">
				<Header type="logo" />
				<div className="container mx-auto max-w-[800px] py-8 px-4 text-black">
					<h2 className="h2 mt-32 mb-12 text-center font-FuturaBold text-2xl text-black lg:mb-24">Impressum</h2>
					<h2 className="mt-8 mb-2 font-FuturaBold text-[20px] leading-tight">MEDIENINHABER</h2>
					<p className=" mb-4">
						Danone Deutschland GmbH
						<br />
						Am Hauptbahnhof 18
						<br />
						60329 Frankfurt
						<br />
						Deutschland
					</p>
					<p className="mb-4">
						Telefon: 089 / 62733 – 0
						<br />
						Fax: 089 / 62733 – 335
					</p>
					<p className="mb-4">
						Registergericht: Amtsgericht Frankfurt am Main
						<br />
						Registernummer: HRB 112989
					</p>
					<p className="mb-4">
						<span className="font-FuturaBold">Geschäftsführung:</span>
						<br />
						Richard Trechman
						<br />
						Marc Widmer
					</p>
					<p className="mb-4">
						<span className="font-FuturaBold">Vorsitzende des Aufsichtsrats:</span>
						<br />
						Corinna Ortner
					</p>
					<p className="mb-4">USt-ID: DE175657037</p>
					<p className="mb-4">
						Plattform der Europäische Kommission zur Online-Streitbeilegung: 
						<a href="https://ec.europa.eu/consumers/odr/" className="underline" target="_blank">
							https://ec.europa.eu/consumers/odr/
						</a>
					</p>
					<p className="mb-4">Bei Fragen, Lob und Kritik, Reklamationen sowie Wünschen und/oder Anregungen zu YoPRO wenden Sie sich bitte an unseren Verbraucherservice.</p>
					<p className="mb-4">
						Dieser ist jederzeit für Sie unter der Telefonnummer {" "}
						<a href="tel:0080080090088" className="underline" target="_blank">
							00800 800 900 88
						</a>
						 oder über das Kontaktformular erreichbar.
					</p>
					<h2 className="mt-8 mb-2 font-FuturaBold text-[20px] font-bold leading-tight">PRESSEKONTAKT</h2>
					<p className="mb-4">
						E-Mail: 
						<a href="mailto:pressestelle@danone.com" className="underline" target="_blank">
							pressestelle@danone.com
						</a>
					</p>
				</div>
				<div className=" py-10 pt-5 text-center text-xl text-red">
					<Link to="/" className="btn btn-hero mt-8 rounded bg-white px-12 py-4 font-FuturaBold text-red">
						Zurück
					</Link>
				</div>
			</div>
		</DocumentMeta>
	)
}
export default Imprint
