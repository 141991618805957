import { useContext } from "react"
import { MAX_PRODUCT_PRICE } from "../../config"
import { Context } from "../../store/store"
import ElemInput from "../elements/Input"
import ElemCheckbox from "../elements/Checkbox"
import React from "react"
import ElemCheckboxProducts from "../elements/CheckboxProducts"

interface ProductsProps {
	formElementChanged: Function
	validation: Object
}
const Product: React.FC<ProductsProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<section className="relative z-40 mx-auto max-w-[900px] ">
			<div className="mx-auto mb-1 grid max-w-[900px] grid-cols-1 items-start gap-1 lg:grid-cols-[1fr_1fr] lg:gap-5">
				<ElemInput
					label={"Kaufbetrag in € exkl. Pfand*"}
					props={{
						type: "number",
						step: "0.01",
						min: "0.1",
						max: MAX_PRODUCT_PRICE,
						name: "purchaseAmount",
						value: globalState.purchaseAmount,
						placeholder: "Kaufbetrag in € exkl. Pfand*",
						required: "required",
						onChange: e => formElementChanged(e, "purchaseAmount"),
					}}
					hasError={validation["invalid_purchaseAmount"] ? true : false}
					className="col-span-2 lg:col-span-1"
					suffix="EUR"
					errMsg={validation["invalid_purchaseAmount_errMsg"] || "Bitte Kaufbetrag eingeben. (max. 5,00 EUR)"}
				/>
				<ElemInput
					label={"Kaufdatum*"}
					props={{
						type: "date",
						name: "purchaseDate",
						value: globalState.purchaseDate,
						required: "required",
						placeholder: "Kaufdatum *",
						min: "2024-07-01",
						minLength: 1,
						onChange: e => formElementChanged(e, "purchaseDate"),
					}}
					// TODO: Datepicker
					hasError={validation["invalid_purchaseDate"] ? true : false}
					className="col-span-2 lg:col-span-1"
					errMsg={validation["invalid_purchaseDate_errMsg"] || "Bitte Kaufdatum eingeben."}
				/>
			</div>
		</section>
	)
}

export default Product
